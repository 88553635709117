import React, { useState } from 'react';
import useForm from 'react-hook-form';
import saIdParser from 'south-african-id-parser';
import queryString from 'query-string';
import { Col, Row } from 'reactstrap';
import { ApiProvider } from 'providers';
import { alpha, phone } from '../../config/regex';
import errorMessages from '../../config/error-messages.json';
import ErrorResult from './ErrorResult';

const CaptureCustomer = props => {
    const {
        stepper,
        setHidden,
        setStepper,
        setVettingResult,
        setCustomerInfo,
        setVetInfo,
        setOptCheck,
    } = props;
    const { register, handleSubmit, errors } = useForm();
    const [ submitting, setSubmitting ] = useState(false);
    const [ hideStartCheckBtn, setHideStartCheckBtn ] = useState(false);
    const [ vetResult, setVetResult ] = useState(null);

    const onSubmit = data => {
        handleStartCheck(data);
    };

    async function handleStartCheck(data) {
        setSubmitting(true);
        const customerInfo = queryString.stringify({
            Name: data.first_name,
            Surname: data.last_name,
            idNumber: data.said_number,
            CellphoneNumber: data.contact_number,
            Source: 'MondoConnect_D',
        });
        const result = await ApiProvider.submitVet(customerInfo);
        setVetResult(result);
        setVetInfo(result);
        setCustomerInfo(data);
        if (result.validateResult) {
            setVettingResult(result);
            setStepper(2);
        } else {
            if (result.validateReason) {
                if (result.validateReason.toLowerCase() === 'not eligible for live vet') {
                    setStepper(3);
                    setHidden(2);
                }
            }
            setHideStartCheckBtn(true);
        }
        setSubmitting(false);
        return true;
    }

    return (
        <div className="pd-t-30 pd-b-10">
            <form method="post" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xs={12} sm={6}>
                        <Row>
                            <Col xs={12}>
                                <div className="wizard clearfix bg-transparent border-0">
                                    <div className="steps clearfix pd-l-0-f mg-b-20">
                                        <ul role="tablist">
                                            <li role="tab" className="first current" aria-disabled="false" aria-selected="true">
                                                <a id="wizard1-t-0" href="#wizard1-h-0" aria-controls="wizard1-p-0" onClick={(e) => e.preventDefault()}>
                                                    <span className="current-info audible">current step: </span>
                                                    <span className="number">1</span> 
                                                    <span className="title">Capture Customer details for realtime vet</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div style={{ display: 'block', marginTop: -40, marginBottom: 30, marginLeft: 40, fontSize: '0.9em' }}>
                                    <span className="tx-color-03">All fields below are required.</span>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <input
                                        name="first_name"
                                        className={`form-control ${errors.first_name ? 'parsley-error' : ''}`}
                                        placeholder="First Name"
                                        disabled={submitting}
                                        ref={register({
                                            required: true,
                                            minLength: 1,
                                            pattern: alpha,
                                        })}
                                    />
                                    {errors.first_name &&
                                    <ul className="parsley-errors-list filled" id="parsley-id-5">
                                        <li className="parsley-required">
                                            {errors.first_name?.type === 'required' ?
                                                errorMessages.customer_capture.first_name.required : 
                                                errorMessages.customer_capture.first_name.invalid}
                                        </li>
                                    </ul>}
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <input
                                        name="last_name"
                                        className={`form-control ${errors.last_name ? 'parsley-error' : ''}`}
                                        placeholder="Last Name"
                                        disabled={submitting}
                                        ref={register({
                                            required: true,
                                            minLength: 3,
                                            pattern: alpha,
                                        })}
                                    />
                                    {errors.last_name &&
                                    <ul className="parsley-errors-list filled" id="parsley-id-5">
                                        <li className="parsley-required">
                                            {errors.last_name?.type === 'required' ?
                                                errorMessages.customer_capture.last_name.required : 
                                                errorMessages.customer_capture.last_name.invalid}
                                        </li>
                                    </ul>}
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <input
                                        name="said_number"
                                        className={`form-control ${errors.said_number ? 'parsley-error' : ''}`}
                                        placeholder="SA ID Number"
                                        disabled={submitting}
                                        ref={register({
                                            required: true,
                                            minLength: 13,
                                            validate: value => saIdParser.validate(value),
                                        })}
                                    />
                                    {errors.said_number &&
                                    <ul className="parsley-errors-list filled" id="parsley-id-5">
                                        <li className="parsley-required">
                                            {errors.said_number?.type === 'required' ?
                                                errorMessages.customer_capture.said_number.required : 
                                                errorMessages.customer_capture.said_number.invalid}
                                        </li>
                                    </ul>}
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <input
                                        name="contact_number"
                                        className={`form-control ${errors.contact_number ? 'parsley-error' : ''}`}
                                        placeholder="Contact Number"
                                        disabled={submitting}
                                        ref={register({
                                            required: true,
                                            minLength: 10,
                                            pattern: phone,
                                        })}
                                    />
                                    {errors.contact_number &&
                                    <ul className="parsley-errors-list filled" id="parsley-id-5">
                                        <li className="parsley-required">
                                            {errors.contact_number?.type === 'required' ?
                                                errorMessages.customer_capture.contact_number.required : 
                                                errorMessages.customer_capture.contact_number.invalid}
                                        </li>
                                    </ul>}
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <label><strong>Marketing opt in?</strong></label>
                                    <div className="radio">
                                        <div className="mg-r-20" style={{ display: 'inline-block' }}>
                                            <input
                                                type="radio"
                                                name="opt_in"
                                                value="Yes"
                                                id="opt_in_yes"
                                                onChange={e => setOptCheck(e.target.checked)}
                                                defaultChecked
                                            />
                                            &nbsp;&nbsp;
                                            <label htmlFor="opt_in_yes">Yes</label>
                                        </div>
                                        <div style={{ display: 'inline-block' }}>
                                            <input
                                                type="radio"
                                                name="opt_in"
                                                value="Yes"
                                                id="opt_in_no"
                                                onChange={e => setOptCheck(!e.target.checked)}
                                            />
                                            &nbsp;&nbsp;
                                            <label htmlFor="opt_in_no">No</label>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            {(stepper === 1 && !hideStartCheckBtn) &&
                            <Col xs={12}>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={submitting}
                                >
                                    {submitting ? 'Processing...' : 'Start Check'}
                                </button>
                            </Col>}
                        </Row>
                    </Col>
                    <Col xs={12} sm={6} className="text-center">
                        {vetResult &&
                        <>
                            {!vetResult.validateResult &&
                            <ErrorResult status={vetResult.validateReason} />}
                        </>}
                    </Col>
                </Row>
            </form>
        </div>
    );
};

export default CaptureCustomer;
