import React from 'react';
import moment from 'moment';

const Footer = () => {
	return (
		<footer className="footer">
			<div>
				<span>Copyright &copy; {moment().format('YYYY')} Rewardsco (Pty) Ltd. | Service Desk Support. </span>
			</div>
		</footer>
	)
};

export default Footer;
