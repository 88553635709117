import React from 'react';
import PropTypes from 'prop-types';

const ErrorResult = props => {
    const { status, buttonClass } = props;
    let showButton = true;
    let title      = null;
    let body       = null;
    let btnClass   = buttonClass;
    let btnText    = 'Cancel';

    // duplicate lead
    if (status.toLowerCase().indexOf('duplicate lead') !== -1) {
        title = 'Duplicate application found';
        body = <p className="mg-y-30">This customer has already applied for a deal within<br/>the last 7 days.</p>
    }

    // network error
    if (status.toLowerCase().indexOf('network error') !== -1) {
        title = 'Network error';
        body = <p className="mg-y-30">An unexpected error has occured. Please try again<br/>or contact support.</p>
        btnClass = 'btn-danger-result';
    }

    // possible fraud
    if (status.toLowerCase().indexOf('fraud check failed') !== -1) {
        title = 'Possible Fraud';
        body = <p className="mg-y-30">This Customer has failed Fraud checks.<br/>We cannot offer them a deal.</p>
        btnClass = 'btn-danger-result btn-solid';
    }

    // not eligible for vet
    if (status.toLowerCase().indexOf('not eligible for live vet') !== -1) {
        title      = 'Not Eligible';
        body       = <p className="mg-y-30">Unfortunately this customer is not eligible for a realtime vet.<br/>Ask customer for preferred network and deal type and submit to<br/>Sales Agent.</p>
        showButton = false;
    }

    // score not available
    if (status.toLowerCase().indexOf('score unavailable') !== -1) {
        title      = 'Score Unavailable';
        body       = <p className="mg-y-30">Unfortunately realtime vet score is currently not available.<br/>Please try again or contact support.</p>
        btnClass   = 'btn-danger-result';
        btnText    = 'Try Again';
        showButton = false;
    }

    return (
        <>
            <div className="pd-t-30 mg-lg-t-60">
                <button type="button" className={`btn btn-result btn-block-result ${btnClass}`}>
                    {title}
                </button>
            </div>
            {body}
            {showButton &&
            <div>
                <a
                    href="/"
                    className="btn btn-secondary mn-wd-150"
                >
                    {btnText}
                </a>
            </div>}
        </>
    );
};

ErrorResult.propTypes = {
    status: PropTypes.string.isRequired,
    buttonClass: PropTypes.string,
};

ErrorResult.defaultProps = {
    buttonClass: 'btn-warning-result',
};

export default ErrorResult;
