import axios from 'axios';
import token from 'basic-auth-token';
import config from '../config';

const { api } = config;
const { livevet, mondo, everlytic, digitalleads } = api;
const liveVetApiUrl      = `${livevet.baseurl[config.stage]}`;
const mondoApiUrl        = `${mondo.baseurl[config.stage]}`;
const digitalLeadsApiUrl = `${digitalleads.baseurl[config.stage]}`;

class ApiProvider {
  static async submitVet(data) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${liveVetApiUrl}?${data}`,
      crossdomain: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response;
      return { validateResult: false, validateReason: err.message };
    });
  }

  static async validateDealCode(code) {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${mondoApiUrl}deals/${code}`,
      crossdomain: true,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async networkProviders() {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${mondoApiUrl}generic/networks`,
      crossdomain: true,
    })
    .then((json) => json.data)
    .then((response) => {
      const { status, data } = response;
      if (status && status === 'OK') {
        const { results } = data;
        return results || [];
      }
      return [];
    })
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async saveLead(data) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${mondoApiUrl}leads/live-vetting`,
      crossdomain: true,
      headers: {
        'Authorization': `Basic ${token('dev', 'Lb1KXh40B29PxReKDLQH3HKpydFGYqvaCvrQpIba')}`,
        'Content-Type': 'application/json',
      },
      data,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      console.log(err)
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async submitLead(data) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${digitalLeadsApiUrl}leads`,
      crossdomain: true,
      data,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async submitOptIn(data) {
    const { baseurl, auth } = everlytic;
    const { apikey, userid } = auth;
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}contacts`,
      crossdomain: true,
      data,
      headers: {
        'Authorization': `Basic ${token(userid, apikey)}`,
        'Content-Type': 'application/json',
      }
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  }

  static async updateLead(data) {
    return await axios({
      mode: 'no-cors',
      method: 'PUT',
      url: `${mondoApiUrl}leads`,
      crossdomain: true,
      headers: {
        'Authorization': `Basic ${token('dev', 'Lb1KXh40B29PxReKDLQH3HKpydFGYqvaCvrQpIba')}`,
        'Content-Type': 'application/json',
      },
      data,
    })
    .then((json) => json.data)
    .then((res) => res)
    .catch((err) => {
      console.log(err)
      if (err.response) return err.response.data;
      return err;
    });
  }
}

export default ApiProvider;
