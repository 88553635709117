import React from 'react';
import { Container } from 'reactstrap';

const Header = () => {
	const logoPath = require('images/logo.svg');
	return (
		<header className="navbar navbar-header navbar-header-fixed">
			<Container>
				<a href="/" id="mainMenuOpen" className="burger-menu">
					<i data-feather="menu"></i>
				</a>
				<div className="navbar-brand">
					<a href="/" className="df-logo">
						<img src={logoPath} className="mg-l-20" alt="Live Vet Chat Agent Capture" height="40" />
					</a>
				</div>
				<div className="navbar-right">
					<h3 className="tx-dark">Live Vet Chat Agent Capture</h3>
				</div>
			</Container>
		</header>
	);
};

export default Header;
