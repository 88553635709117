import React, { useState } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Switch
} from 'react-router-dom';

import { AuthContext } from 'context/auth';

// pages/routes
import Landing from 'pages/Landing';
import Results from 'pages/Result';

// page not found
import PageNotFound from 'pages/PageNotFound';

// private routes

const App = () => {
	const [authTokens, setAuthTokens] = useState();

	const setTokens = (data) => {
		localStorage.setItem('tokens', JSON.stringify(data));
		setAuthTokens(data);
	}

	return (
		<AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
			<Router basename={process.env.PUBLIC_URL}>
				<Switch>
					<Redirect exact from="/landing" to="/" />
					<Route exact path="/" component={Landing} />
					<Route exact path="/result/:status" component={Results} />
					<Route component={PageNotFound} />
				</Switch>
			</Router>
		</AuthContext.Provider>
	)
}

export default App;
