import React from 'react';
import { Header, Footer } from '../containers';

const Layout = props => {
    const { header, footer } = props;
    return (
        <React.Fragment>
            <Header {...header} />
            <div className="page-body-container" style={{ marginTop:60 }}>
                {props.children}
            </div>
            <Footer {...footer} />
        </React.Fragment>
    );
};

export default Layout;