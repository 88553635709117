import React from 'react';

const PageNotFound = props => {
    const { history, location } = props;

    // change the url
    if (history) {
        if (location.pathname.indexOf('/404') === -1) {
            history.push('/404');
        }
    }

    return (
        <div className="content content-fixed content-auth-alt">
            <div className="container ht-100p tx-center">
                <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                    <div className="wd-70p wd-sm-250 wd-lg-300 mg-b-50">
                        <img src={require('images/logo.svg')} className="img-fluid" alt="Rewardsco Live Vet Chat Agent Capture" />
                        <div className="t-color-03 pd-t-5">Live Vet Chat Agent Capture</div>
                    </div>
                    <h1 className="tx-color-01 tx-24 tx-sm-32 tx-lg-36 mg-xl-b-5">404 Page Not Found</h1>
                    <h5 className="tx-16 tx-sm-18 tx-lg-20 tx-normal mg-b-20">Oopps. The page you were looking for doesn't exist.</h5>
                    <p className="tx-color-03 mg-b-30">You may have mistyped the address or the page may have moved.</p>
                    <div className="d-flex mg-b-40">
                        <a href="/" className="btn btn-primary bd-0 mg-l-5 pd-sm-x-25">
                            Go to home
                        </a>
                    </div>
                    <div className="tx-12 tx-color-03 mg-t-15">
                        COPYRIGHT &copy; 2020 REWARDSCO. ALL RIGHTS RESERVED.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
