import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import Select from 'react-select';
import PropTypes from 'prop-types';
import isValidEmail from 'is-valid-email';
import { Col, Row } from 'reactstrap';
import { ApiProvider } from 'providers';
import errorMessages from '../../config/error-messages.json';

const dealTypes = [
    { value: 'All Deal Types', label: 'All Deal Types' },
    { value: 'Data SIM', label: 'Data SIM' },
    { value: 'Voice SIM', label: 'Voice SIM' },
    { value: 'Router', label: 'Router' },
    { value: 'Device', label: 'Device' },
    { value: 'Laptop', label: 'Laptop' },
];

const channels = [
    { value: 'Web Chat', label: 'Web Chat' },
    { value: 'Facebook', label: 'Facebook' },
    { value: 'Email', label: 'Email' },
    { value: 'WhatsApp', label: 'WhatsApp' },
    { value: 'Inbound', label: 'Inbound' },
    { value: 'Moneyshop', label: 'Moneyshop' },
];

const AdditionalCustomerInfo = props => {
    const {
        stepIndicator,
        customerInfo,
        vetInfo,
        customerDeclined,
        setShowResults,
        vettingResult,
        showCustomerDropOff,
        approvedNetworks,
        optInCheck,
    } = props;
    const { register, handleSubmit, errors, setError, clearError, unregister } = useForm();
    const [ networks, setNetworks ] = useState([]);
    const [ submitting, setSubmitting ] = useState(false);
    const [ validating, setValidating ] = useState(false);
    const [ canSubmit, setCanSubmit ] = useState(false);
    const [ selectedDeal, setSelectedDeal ] = useState({});
    const [ dealCodeEntered, setDealCodeEntered ] = useState(false);
    const [ customerDropOff, setCustomerDropOff ] = useState(false);
    const [ showDealCode, setShowDealCode ] = useState(true);
    const [ dealType, setDealType ] = useState(null);
    const [ preferredNetwork, setPreferredNetwork ] = useState(null);
    const [ channel, setChannel ] = useState(null);
    const [ email, setEmail ] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const networks = [];
            const result = await ApiProvider.networkProviders();
            if (result.length > 0) {
                result.map(item => networks.push({ value: item.name, label: item.name }));
            }
            setNetworks(networks);
        };
        fetchData();
    }, [ setNetworks ]);

    const onSubmit = data => {
        handleSubmitLead(data);
    };

    async function handleSubmitLead(data) {
        if (!channel) {
            alert('Please select channel!')
            return false;
        }

        setSubmitting(true);
        let product = '';
        let network = '';
        let dealId  = '';
        let price   = '';
        let score   = 'DECLINED';
        let source  = 'Chat_BV';
        let simOnly = 0;

        // selected deal info
        if (selectedDeal.id) {
            dealId  = selectedDeal.id;
            product = `${selectedDeal.dealsheet_no} - ${selectedDeal.device.manufacturer} ${selectedDeal.device.model} on ${selectedDeal.package.package_name}`;
            simOnly = (selectedDeal.device.device_type.name.toLowerCase().indexOf('sim') !== -1) ? 1 : 0;
            network = selectedDeal.package.network_provider.name;
            price   = selectedDeal.price;
        } else {
            network = preferredNetwork;
            product = dealType;
        }

        // vetting info
        if (vetInfo.validateResult) {
            source = 'Chat_LV';
            if (!customerDeclined) {
                score = 'APPROVED';
            }
        }

        // ::: update :::
        // When Approved: Campaign => Pre-Scored Value

        // mondo lead object/data
        const mondoLead = {
            score,
            source,
            product,
            deal_id: dealId,
            sim_only: simOnly,
            product_price: price,
            opt_in: (optInCheck) ? 1 : 0,
            alternate_contact_number: '',
            employee_no: data.employee_number,
            customer_drop_off: customerDropOff,
            id_number: customerInfo.said_number,
            customer_name: customerInfo.first_name,
            customer_surname: customerInfo.last_name,
            primary_contact_number: customerInfo.contact_number,
            preferred_network: network,
            utm_source: channel,
            email_address: email,
            ip_address: null,
            channel: 'LVCA',
        };

        // capture user lead into mondo db
        const result = await ApiProvider.saveLead(mondoLead);
        setSubmitting(false);
        if (result) {
            if (result.status) {
                if (result.status === 'OK') {
                    if (result.data && result.data.id) {
                        // time-to-touch digital leads api object/data
                        let campaign = '';
                        if (vettingResult.networkResponses) {
                            const filter = vettingResult.networkResponses.filter(item => item.network.toLowerCase() === network.toLowerCase());
                            if (filter.length > 0) campaign = filter[0].campaign || '';
                        }
                        const digitalLead = {
                            product,
                            source,
                            sim_only: simOnly || 0,
                            utm_source: channel,
                            utm_medium: source,
                            email_address: email,
                            preferred_network: network,
                            alternate_contact_number: '',
                            is_duplicate: result.data.exists ? true : false,
                            external_lead_ref: result.data.id,
                            idnumber: customerInfo.said_number,
                            customer_name: customerInfo.first_name,
                            customer_surname: customerInfo.last_name,
                            primary_contact_number: customerInfo.contact_number,
                            acquisition_date: new Date().toISOString(),
                            sale_date: new Date().toISOString(),
                            prescored_value: campaign || '',
                            prescored_network: (network === 'All Networks') ? 'MTN' : network,
                            employee_no: data.employee_number || '',
                            permission_to_vet: true,
                            drop_off_chat: false,
                            is_eligible: true,
                            is_active: true,
                        };
                        // submit lead to time-to-touch digital leads api
                        const digitalLeadResponse = await ApiProvider.submitLead(digitalLead);

                        // update lead: log request and response from digital leads api
                        await ApiProvider.updateLead({
                            id: result.data.id,
                            req: digitalLead,
                            res: digitalLeadResponse,
                            status: 'SUCCESS'
                        });
                    }
                    setShowResults(true);
                    return true;
                }
            }
            alert(result.message || 'An error has occurred!');
        } else alert('Error!');
        return true;
    }
    
    async function handleValidateDealCode(value) {
        setSelectedDeal({});
        if (value.length === 0) {
            setDealCodeEntered(false);
            clearError('deal_code');
            return true;
        }
        setDealCodeEntered(true);
        setValidating(true);
        const result = await ApiProvider.validateDealCode(value);
        const { status, data } = result;
        if (status && status === 'OK') {
            if (data && data.id) {
                clearError('deal_code');
                setSelectedDeal(data);
            } else {
                setError('deal_code', 'invalid', errorMessages.additional_customer_info.deal_code.invalid);
            }
        }
        setValidating(false);
        // console.log(result)
        // if (!result.id) {
        //     setError('deal_code', 'invalid', errorMessages.additional_customer_info.deal_code.invalid);
        // } else {
        //     setSelectedDeal(result);
        //     clearError('deal_code');
        // }
        // setValidating(false);
    }

    function handleValidateEmail(value) {
        setCanSubmit(false);
        clearError('email_address');
        setEmail(null);

        // empty value
        if (value.length === 0) {
            setDealCodeEntered(false);
            return false;
        }

        // validate email
        if (isValidEmail(value)) {

            setEmail(value);

            // email valid | all networks have been declined
            if (customerDeclined) {
                if (channel && channel.length > 0) {
                    setCanSubmit(true);
                    return true;
                }
            }

            // email valid | valid deal code entered
            if (selectedDeal.id) {
                if (channel && channel.length > 0) {
                    setCanSubmit(true);
                    return true;
                }
            }
            // email valid | deal type & network selected
            if (dealType && preferredNetwork) {
                if (dealType.length > 0 && preferredNetwork.length > 0) {
                    if (channel && channel.length > 0) {
                        unregister("deal_code");
                        setShowDealCode(false);
                        setCanSubmit(true);
                        return true;
                    }
                }
            }
        } else {
            setError('email_address', 'invalid');
        }
        return false;
    }

    function handleSelectNetwork(value) {
        setPreferredNetwork(value);
        if (isValidEmail(email)) {
            if (dealType) {
                if (dealType.length > 0) {
                    unregister("deal_code");
                    setShowDealCode(false);
                    setCanSubmit(true);
                    return true;
                }
            }
        }
        setShowDealCode(true);
        return false;
    }

    function handleSelectDealType(value) {
        setDealType(value);
        if (isValidEmail(email)) {
            if (preferredNetwork) {
                if (preferredNetwork.length > 0) {
                    unregister("deal_code");
                    setShowDealCode(false);
                    setCanSubmit(true);
                    return true;
                }
            }
        }
        setShowDealCode(true);
        return false;
    }

    function handleSelectChannel(value) {
        setChannel(value);
        if (isValidEmail(email)) {
            if (dealType && dealType.length > 0) {
                if (preferredNetwork && preferredNetwork.length > 0) {
                    setCanSubmit(true);
                    return true;
                }
            }
        }
    }

    const handleCustomerDropOff = checked => {
        setCustomerDropOff(checked);
        setCanSubmit(checked);
        if (checked) {
            setDealType('All Deal Types');
            if (approvedNetworks.length === 1) {
                setPreferredNetwork(approvedNetworks[0]);
            }
            if (approvedNetworks.length > 1) {
                setPreferredNetwork('All Networks');
            }
            if (!selectedDeal.id) {
                setShowDealCode(false);
            }
        } else {
            setShowDealCode(true);
        }
    };

    return (
        <div className="pd-t-30 pd-b-10">
            <form method="post" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xs={12}>
                        <div className="wizard clearfix bg-transparent border-0">
                            <div className="steps clearfix pd-l-0-f mg-b-20">
                                <ul role="tablist">
                                    <li role="tab" className="first current" aria-disabled="false" aria-selected="true">
                                        <a id="wizard1-t-0" href="#wizard1-h-0" aria-controls="wizard1-p-0" onClick={(e) => e.preventDefault()}>
                                            <span className="current-info audible">current step: </span>
                                            <span className="number">{stepIndicator}</span> 
                                            <span className="title">Additional Customer Information</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div style={{ display: 'block', marginTop: -40, marginBottom: 30, marginLeft: 40, fontSize: '0.9em' }}>
                            {customerDeclined
                            ? (
                            <span className="tx-color-03">
                                If Customer agrees to passing on lead to a Mondo Partner, please enter
                                Customer's email address below and click Submit Lead.
                            </span>) : (
                            <span className="tx-color-03">
                                Once the Customer has agreed on a deal, please copy and paste the Deal Code
                                (obtained from the mondo.co.za URL) into the text box below.
                            </span>)}
                        </div>
                    </Col>
                </Row>
                {!customerDeclined &&
                <Row>
                    {showDealCode &&
                    <Col xs={12} sm={6}>
                        <div className="form-group">
                            <input
                                name="deal_code"
                                className={`form-control ${errors.deal_code ? 'parsley-error' : ''}`}
                                placeholder="Deal Code"
                                disabled={submitting || validating}
                                ref={register({
                                    required: true,
                                    minLength: 4,
                                })}
                                onBlur={(e) => handleValidateDealCode(e.target.value)}
                                onKeyUp={() => {
                                    setSelectedDeal({});
                                    clearError('deal_code');
                                    clearError('email_address');
                                    return true;
                                }}
                            />
                        </div>
                    </Col>}
                    {showDealCode &&
                    <Col xs={12} sm={6}>
                        <div className={`bd-l bd-3 pd-l-10 pd-y-5 tx-color-03 tx-13 ${errors.deal_code ? 'has-error' : ''}`}>
                            {errors.deal_code &&
                            <>
                            {errors.deal_code?.type === 'required' ?
                                errorMessages.additional_customer_info.deal_code.required : 
                                errorMessages.additional_customer_info.deal_code.invalid}
                            </>}
                            {selectedDeal.id &&
                            <>
                                <strong>Selected Deal</strong><br/>
                                Device: {selectedDeal.device.manufacturer} {selectedDeal.device.title}<br/>
                                Package: {selectedDeal.package.package_name}<br/>
                                Price: {`${selectedDeal.price} x ${selectedDeal.dealsheet_period} months`}
                            </>}
                            {(!selectedDeal.id && !errors.deal_code) &&
                            <>
                                Enter if Customer wants a specific deal. If this is entered, then
                                Preferred Network and Preferred Deal Type IS NOT required.
                            </>}
                        </div>
                    </Col>}
                    {!dealCodeEntered &&
                    <Col xs={12} sm={6}>
                        <div className="form-group">
                            <Select
                                name="preferred_network"
                                options={networks}
                                isLoading={networks.length === 0}
                                isDisabled={networks.length === 0 || submitting}
                                onChange={item => handleSelectNetwork(item.value)}
                            />
                        </div>
                    </Col>}
                    {!dealCodeEntered &&
                    <Col xs={12} sm={6}>
                        <div className="bd-l bd-3 pd-l-10 pd-y-5 tx-color-03 tx-13">
                            Enter if Customer wants a specific deal. Sale Agent will find and
                            sell deal to Customer based on Preferred Network.
                        </div>
                    </Col>}
                    {!dealCodeEntered &&
                    <Col xs={12} sm={6}>
                        <div className="form-group">
                            <Select
                                name="preferred_deal_type"
                                options={dealTypes}
                                isLoading={dealTypes.length === 0}
                                isDisabled={dealTypes.length === 0 || submitting}
                                onChange={item => handleSelectDealType(item.value)}
                            />
                        </div>
                    </Col>}
                    {!dealCodeEntered &&
                    <Col xs={12} sm={6}>
                        <div className="bd-l bd-3 pd-l-10 pd-y-5 tx-color-03 tx-13">
                            Enter if Customer wants a specific deal. Sales Agent will find and
                            sell deal to Customer based on this selection.
                        </div>
                    </Col>}
                </Row>}
                <Row>
                    <Col xs={12} sm={6}>
                        <div className="form-group">
                            <Select
                                name="channel"
                                options={channels}
                                isLoading={channels.length === 0}
                                isDisabled={channels.length === 0 || submitting}
                                onChange={item => handleSelectChannel(item.value)}
                                placeholder="Select Channel..."
                            />
                        </div>
                    </Col>
                    <Col xs={12} sm={6}>&nbsp;</Col>
                    <Col xs={12} sm={6}>
                        <div className="form-group">
                            <input
                                name="email_address"
                                className={`form-control ${errors.email_address ? 'parsley-error' : ''}`}
                                placeholder="Email Address"
                                disabled={submitting || validating}
                                onKeyUp={(e) => handleValidateEmail(e.target.value)}
                            />
                            {errors.email_address &&
                            <ul className="parsley-errors-list filled" id="parsley-id-5">
                                <li className="parsley-required">
                                    {errors.email_address?.type === 'required' ?
                                        errorMessages.additional_customer_info.email_address.required : 
                                        errorMessages.additional_customer_info.email_address.invalid}
                                </li>
                            </ul>}
                        </div>
                    </Col>
                    <Col xs={12} sm={6}>&nbsp;</Col>
                    <Col xs={12} sm={6}>
                        <div className="form-group">
                            <input
                                name="employee_number"
                                className={`form-control ${(errors.employee_number) ? 'parsley-error' : ''}`}
                                placeholder="Employee Number"
                                disabled={submitting}
                                ref={register({
                                    required: true,
                                    minLength: 4,
                                })}
                            />
                            {errors.employee_number &&
                            <ul className="parsley-errors-list filled" id="parsley-id-5">
                                <li className="parsley-required">
                                    {errors.employee_number?.type === 'required' ?
                                        errorMessages.customer_capture.employee_number.required : 
                                        errorMessages.customer_capture.employee_number.invalid}
                                </li>
                            </ul>}
                        </div>
                    </Col>
                    <Col xs={12} sm={6}>&nbsp;</Col>
                    {showCustomerDropOff &&
                    <Col xs={12} sm={12}>
                        <div className="form-group">
                            <label htmlFor="customer-drop-off">
                                <input
                                    type="checkbox"
                                    name="customer-drop-off"
                                    id="customer-drop-off"
                                    onChange={e => handleCustomerDropOff(e.target.checked)}
                                />
                                &nbsp;
                                Customer Drop-Off Chat
                            </label>
                        </div>
                    </Col>}
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        <button
                            type="submit"
                            className={`btn btn-primary mg-r-15 ${canSubmit ? '' : 'btn-disabled'}`}
                            disabled={submitting || validating || !canSubmit}
                        >
                            {submitting ? 'Processing...' : 'Submit Lead'}
                        </button>
                        <a
                            href="/"
                            className="btn btn-secondary"
                        >
                            &nbsp;&nbsp;&nbsp;Cancel&nbsp;&nbsp;&nbsp;&nbsp;
                        </a>
                    </Col>
                </Row>
            </form>
        </div>
    );
};

AdditionalCustomerInfo.propTypes = {
    stepIndicator: PropTypes.number,
};

AdditionalCustomerInfo.defaultProps = {
    stepIndicator: 3,
};

export default AdditionalCustomerInfo;
