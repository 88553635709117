import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { Layout } from 'containers';
import { AdditionalCustomerInfo, CaptureCustomer, LeadResults, VetResults } from 'components';

const Landing = () => {
	const [ stepper, setStepper ] = useState(1);
	const [ vettingResult, setVettingResult ] = useState({});
    const [ customerInfo, setCustomerInfo ] = useState({});
    const [ vetInfo, setVetInfo ] = useState({});
	const [ hidden, setHidden ] = useState(null);
	const [ customerDeclined, setCustomerDeclined ] = useState(false);
	const [ showResults, setShowResults ] = useState(false);
    const [ showCustomerDropOff, setShowCustomerDropOff ] = useState(true);
    const [ approvedNetworks, setApprovedNetworks ] = useState([]);
    const [ optInCheck, setOptCheck ] = useState(true);
	return (
		<Layout>
			<Container>
				{showResults && <LeadResults />}
				{!showResults &&
				<div className="pd-b-50">
					{stepper >= 1 &&
					<CaptureCustomer
						setHidden={setHidden}
						stepper={stepper}
						setStepper={setStepper}
						vettingResult={vettingResult}
						setVettingResult={setVettingResult}
						setCustomerInfo={setCustomerInfo}
						setVetInfo={setVetInfo}
						setOptCheck={setOptCheck}
					/>}
					{(stepper >= 2 && hidden !== 2) &&
					<VetResults
						setStepper={setStepper}
						stepIndicator={2}
						vettingResult={vettingResult}
						customerDeclined={customerDeclined}
						setCustomerDeclined={setCustomerDeclined}
						setShowCustomerDropOff={setShowCustomerDropOff}
						setApprovedNetworks={setApprovedNetworks}
						approvedNetworks={approvedNetworks}
					/>}
					{(stepper >= 2 && hidden !== 3) &&
					<AdditionalCustomerInfo
						hidden={hidden}
						stepper={stepper}
						setStepper={setStepper}
						stepIndicator={hidden === 2 ? 2 : 3}
						vettingResult={vettingResult}
						customerInfo={customerInfo}
						vetInfo={vetInfo}
						customerDeclined={customerDeclined}
						setShowResults={setShowResults}
						showCustomerDropOff={showCustomerDropOff}
						approvedNetworks={approvedNetworks}
						optInCheck={optInCheck}
					/>}
				</div>}
			</Container>
		</Layout>
	);
};

export default Landing;
