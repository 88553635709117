var stage = 'development';
if (window.location) {
    var arr = window.location.hostname.split('.');
    if (arr[0] !== 'localhost') {
        stage = arr[0];
        if (stage === 'dev-lvca' || stage === 'development') {
            stage = 'development';
        } else if (stage === 'test-lvca') {
            stage = 'test';
        } else {
            stage = 'production';
        }
    }
}

export default {
    stage,
    externalurl: {
        mondo: {
            development: 'https://dev.mondo.co.za/',
            test: 'https://test.mondo.co.za/',
            production: 'https://www.mondo.co.za/',
        }
    },
    api: {
        everlytic: {
            auth: {
                apikey: 'zQ74ycFP32LWeylQ7xibd0UPcfS67YzG_21',
                userid: 'Justin_McCall'
            },
            baseurl: 'https://mondoconnect.everlytic.net/api/2.0/',
            listid: ''
        },
        mondo: {
            baseurl: {
                development: 'https://dev-api.mondo.co.za/',
                test: 'https://test-api.mondo.co.za/',
                production: 'https://api.mondo.co.za/',
            }
        },
        livevet: {
            baseurl: {
                development: 'https://test.api.rewards.co.za/Rewardsco.Service.LiveVet/LiveVet/VetLead',
                test: 'https://test.api.rewards.co.za/Rewardsco.Service.LiveVet/LiveVet/VetLead',
                production: 'https://api.rewards.co.za/Rewardsco.Service.LiveVet/LiveVet/VetLead',
            }
        },
        digitalleads: {
            baseurl: {
                development: 'https://p0ug50haw5.execute-api.eu-west-2.amazonaws.com/dev/',
                test: 'https://p0ug50haw5.execute-api.eu-west-2.amazonaws.com/dev/',
                production: 'https://hzehadlis8.execute-api.eu-west-2.amazonaws.com/prod/',
            }
        }
    }
};
