import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Container } from 'reactstrap';
import { Layout } from 'containers';
import { FeatherIcon } from 'components';

const Result = props => {
    const { icon, link, title } = props;

    if (!window.document.referrer) {
        window.location = '/';
        return false;
    }

    return (
        <Layout>
            <Container>
				<div className="lead-submit-results">
                    <Card>
                        <CardBody>
                            <div className="result-container">
                                <div className="icon-box">
                                    <div className="icon">
                                        <FeatherIcon icon={icon} width="30" height="30" />
                                    </div>
                                </div>
                                <div className="content">
                                    <h2>{title}</h2>
                                    {link}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Container>
        </Layout>
    );
};

Result.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.any,
};

Result.defaultProps = {
    icon: 'check',
    title: 'Lead Submitted',
    link: <a href="/">Return to capture Form</a>,
};

export default Result;
