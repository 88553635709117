import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { FeatherIcon, Loading } from 'components';
import config from '../../config';

const mondoUrl = `${config.externalurl.mondo[config.stage]}deals/search`;

const VetResults = props => {
    const {
        approvedNetworks,
        stepIndicator,
        vettingResult,
        setCustomerDeclined,
        setShowCustomerDropOff,
        setApprovedNetworks,
    } = props;
    const { networkResponses } = vettingResult;
    const [ processing, setProcessing ] = useState(true);
    const [ headingText, setHeadingText ] = useState(null);
    const [ label, setLabel ] = useState(null);

    useEffect(() => {
        if (networkResponses) {
            if (networkResponses.length > 0) {
                let declined = true;
                const approved = approvedNetworks;
                networkResponses.map(item => {
                    if (item.networkResult) {
                        if (item.networkResult === 'APPROVED') {
                            /**
                             * Show Customer Drop Off Checkbox:
                             * - If at least one approved network on live vet results,
                             *   OR
                             * - If illegible for live vet
                             */
                            approved.push(item.network);
                            // setShowCustomerDropOff(true);
                            declined = false;
                        }
                    }
                    return true;
                });
                setApprovedNetworks(approved);
                setCustomerDeclined(declined);
                setProcessing(false);
                if (declined) {
                    setHeadingText('Unfortunately, this Customer has been declined on ALL networks, we are unable to offer them a deal. Ask Customer to consider passing lead on to Mondo partners.');
                    setLabel(<div className="pd-t-30 tx-danger"><button type="button" className="btn btn-outline-danger"><strong>DECLINED</strong></button></div>);
                    return true;
                }
            }
        }
        setHeadingText('Results from the Realtime Vet are shown below. Run through the deals available on www.mondo.co.za with the Customer by clicking on "Search Deals".');
        setLabel(<div className="pd-t-30 tx-success"><button type="button" className="btn btn-outline-success"><strong>APPROVED</strong></button></div>);
        setProcessing(false);
    }, [
        approvedNetworks,
        setApprovedNetworks,
        setProcessing,
        setHeadingText,
        setLabel,
        setCustomerDeclined,
        networkResponses,
        setShowCustomerDropOff,
    ]);

    const getNetworkMessage = data => {
        if (typeof data.networkMessage === 'string' && data.networkMessage.length > 0) {
            const obj = JSON.parse(data.networkMessage);
            if (data.networkResult === 'APPROVED') {
                // return approval status
                return obj.EnquiryStatus ? obj.EnquiryStatus : '';
            }
            // return error message
            return obj.ErrorDescription ? obj.ErrorDescription : '';
        }
        return data.networkMessage;
    };

    return (
        <div className="pd-t-30 pd-b-10">
            {processing && <Loading text="Please wait..." />}
            <Row>
                <Col xs={12} sm={9}>
                    <div className="wizard clearfix bg-transparent border-0">
                        <div className="steps clearfix pd-l-0-f mg-b-20">
                            <ul role="tablist">
                                <li role="tab" className="first current" aria-disabled="false" aria-selected="true">
                                    <a id="wizard1-t-0" href="#wizard1-h-0" aria-controls="wizard1-p-0" onClick={(e) => e.preventDefault()}>
                                        <span className="current-info audible">current step: </span>
                                        <span className="number">{stepIndicator}</span> 
                                        <span className="title">Realtime Vet Results</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div style={{ display: 'block', marginTop: -40, marginBottom: 30, marginLeft: 40, fontSize: '0.9em' }}>
                        <span className="tx-color-03">
                            {headingText}
                        </span>
                    </div>
                </Col>
                <Col xs={12} sm={3} className="text-right">
                    {label}
                </Col>
            </Row>
            <div className="table-responsive mg-t-5">
                <table className="table table-dark table-hover table-striped mg-b-0">
                    <thead>
                        <tr>
                            <th>Network</th>
                            <th>Approved?</th>
                            <th>Spend Limit</th>
                            <th>Device</th>
                            <th>Message</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {networkResponses && networkResponses.map(item => {
                            let searchLink = '';
                            if (item.networkResult === 'APPROVED') {
                                searchLink += mondoUrl;
                                searchLink += `?network=${item.network}`;
                                searchLink += (item.networkSpendLimit && item.networkSpendLimit > 0) ? `&price=[0,${parseInt(item.networkSpendLimit)}]` : '';
                                if (item.networkProduct) {
                                    if (item.networkProduct.toLowerCase() === 'sim only') {
                                        searchLink += '&device=SIM Only';
                                    }
                                }
                            }
                            return (
                                <tr key={item.network}>
                                    <td>{item.network}</td>
                                    <td>{item.networkResult === 'APPROVED' ? 'Yes' : 'No'}</td>
                                    <td>
                                        {item.network === 'MTN' ?
                                        <>
                                            {item.networkResult === 'APPROVED' && <span>{item.networkSpendLimit}</span>}
                                            {item.networkResult === 'DECLINED' && <span>-</span>}
                                        </> :
                                        <span>n/a</span>}
                                    </td>
                                    <td>
                                        {item.networkResult === 'APPROVED' && <span>{item.networkProduct ? item.networkProduct : ''}</span>}
                                        {item.networkResult === 'DECLINED' && <span>-</span>}
                                    </td>
                                    <td>{item.networkMessage ? getNetworkMessage(item) : 'n/a'}</td>
                                    <td className="text-right">
                                        {item.networkResult === 'APPROVED' &&
                                        <a
                                            href={searchLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Search Deals
                                            <FeatherIcon icon="external-link" width="14" height="14" classes="mg-l-5" />
                                        </a>}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

VetResults.propTypes = {
    stepIndicator: PropTypes.number,
};

VetResults.defaultProps = {
    stepIndicator: 2,
};

export default VetResults;
